import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./index.module.scss";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import Modal from "../../../elements/modal/Modal";
import { useIskraV2 } from "../../../../module/hook/v2/iskra";
import { ExchangeCard } from "./card";
import { LinkButton } from "../../../elements/linkbutton";

export const Exchange = () => {
  const { getContractInfo, contractLoading, balanceLoading, getBalanceByName } = useIskraV2();

  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContents, setModalContents] = useState("");
  const [contractDataList, setContractDataList] = useState([]);
  const [selectedContractData, setSelectedContractData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  useEffect(() => {
    if (contractLoading /* || balanceLoading */) {
      return;
    }

    const newContractDataList = [];
    newContractDataList.push({
      tapName: "KAIA Network",
      enabled: true,
      exchangeContractInfo: getContractInfo("kaia", "exchange"),
      exchangePoolContractInfo: getContractInfo("kaia", "exchange_pool"),
      sourceTokenContractInfo: getContractInfo("kaia", "candy"),
      targetTokenContractInfo: getContractInfo("kaia", "isk"),
      getSourceTokenBalance: () => getBalanceByName("kaia", "candy"),
      getTargetTokenBalance: () => getBalanceByName("kaia", "isk"),
      sourceTokenImg: require("../../../../assets/images/homepage/candy_kaia_icon.png"),
      targetTokenImg: require("../../../../assets/images/homepage/iskra_kaia_icon.png"),
      sourceTokenSymbol: "CANDY",
      targetTokenSymbol: "ISK",
      chainSymbol: "KAIA",
      chainIcon: require("../../../../assets/images/homepage/kaia_chain_icon.png"),
      chainDelay: 1000,
    });
    newContractDataList.push({
      tapName: "Base Network",
      enabled: false,
      exchangeContractInfo: getContractInfo("base", "exchange"),
      exchangePoolContractInfo: getContractInfo("base", "exchange_pool"),
      sourceTokenContractInfo: getContractInfo("base", "jelly"),
      targetTokenContractInfo: getContractInfo("base", "gram"),
      getSourceTokenBalance: () => getBalanceByName("base", "jelly"),
      getTargetTokenBalance: () => getBalanceByName("base", "gram"),
      sourceTokenImg: require("../../../../assets/images/homepage/jelly_base_icon.png"),
      targetTokenImg: require("../../../../assets/images/homepage/gram_base_icon.png"),
      sourceTokenSymbol: "JELLY",
      targetTokenSymbol: "GRAM",
      chainSymbol: "BASE",
      chainIcon: require("../../../../assets/images/homepage/base_eth_chain_icon.png"),
      chainDelay: 10000,
    });

    setContractDataList(newContractDataList);

    const queryParams = new URLSearchParams(window.location.search);
    const ex = queryParams.get("ex");
    if (newContractDataList.length > 1) {
      if (ex) {
        if (ex === "candy") {
          setSelectedContractData(newContractDataList[0]);
        } else if (ex === "jelly" && newContractDataList[1].enabled) {
          setSelectedContractData(newContractDataList[1]);
        } else {
          setSelectedContractData(newContractDataList[0]);
        }
      } else {
        setSelectedContractData(newContractDataList[0]);
      }
    }
  }, [contractLoading, balanceLoading]);

  function finishTx(modalTitle, modalContents) {
    setVisibleModalConfirm(true);
    setModalTitle(modalTitle);
    setModalContents(modalContents);
  }

  return (
    <main className={styles.main}>
      <section className={styles.section}>
        <div className={styles.background}>
          <img className={styles.pc_img} src={require("../../../../assets/images/homepage/exchange_bg.png")} alt="bg" />
          <img className={cn(styles.mobile_img, styles.mobile_img_1)} src={require("../../../../assets/images/homepage/exchange_mobile_bg1.png")} alt="bg" />
          <img className={cn(styles.mobile_img, styles.mobile_img_2)} src={require("../../../../assets/images/homepage/exchange_mobile_bg2.png")} alt="bg" />
          <img className={cn(styles.mobile_img, styles.mobile_img_3)} src={require("../../../../assets/images/homepage/exchange_mobile_bg3.png")} alt="bg" />
        </div>
        <div className={cn("container", styles.container)}>
          <div className={styles.title}>$ISK Exchange</div>
          <div className={styles.content}>
            Exchange CANDY you obtained from 'Norma in Metaland' for $ISK Tokens.
            <br />
            $ISK tokens can be swapped with KLAY, ISK, oUSDT, etc. in Iskra DEX.
          </div>
          {!selectedContractData ? (
            <div className={styles.exchange_empty_tap_container} />
          ) : (
            <div className={styles.exchange_tap_container}>
              {contractDataList.map((contractData, index) => (
                <div
                  key={index}
                  className={cn(styles.exchange_tap, { [styles.selected]: selectedContractData?.tapName === contractData.tapName }, { [styles.disabled]: !contractData.enabled })}
                  onClick={() => {
                    if (!contractData.enabled) {
                      return;
                    }
                    setSelectedContractData(contractData);
                  }}
                >
                  {contractData.tapName}
                </div>
              ))}
            </div>
          )}
          <div className={styles.exchange_info_container}>
            <ExchangeCard selectedContractData={selectedContractData} finishTx={finishTx} />
          </div>
          <div className={styles.bridge_container}>
            <LinkButton className={styles.bridge_btn} link="/" isBlank={false}>
              Go to bridge
            </LinkButton>
          </div>
          <div className={styles.exp}>
            ※ The exchange rate is set based on the ratio between in-game Candy token and exchangeable ISK token in real time. <br />※ The exchange fee is 10% of total volume of exchange.
          </div>
        </div>
      </section>

      <Modal outerClassName={styles.confirm_modal} visible={visibleModalConfirm} unquenchable={false} onClose={() => setVisibleModalConfirm(false)}>
        <div className={styles.modal_content}>
          <div className={styles.modal_title}>{modalTitle}</div>
          <div className={styles.modal_content_box}>
            <div className={styles.modal_contents}>{modalContents}</div>
          </div>
          <div className={styles.modal_btn_container}>
            <button className={cn(styles.modal_btn, { [styles.error]: modalTitle === "Error" })} onClick={() => setVisibleModalConfirm(false)}>
              OK
            </button>
          </div>
        </div>
      </Modal>
    </main>
  );
};
