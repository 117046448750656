import Web3Adapter from "../adapter";
import ERC20Abi from "../../../../../../assets/contract/ERC20.abi";
import { call, send } from "../../module";
import { convertFromWei } from "../../../../../../utility/helpers";
import Web3 from "web3";

class ERC20Adapter extends Web3Adapter {
  async loadAbis() {
    super.loadAbis();

    const res = await fetch(ERC20Abi);
    const data = await res.text();

    this.erc20Contract = new this.web3.eth.Contract(JSON.parse(data));
  }

  async balanceOf(walletAddress, contractAddress) {
    if (!this.erc20Contract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: walletAddress,
        data: this.erc20Contract.methods.balanceOf(walletAddress).encodeABI(),
      },
    ];

    return new Promise((resolve, reject) => {
      this.provider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async totalSupply(contractAddress) {
    if (!this.erc20Contract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: this.iskraInterface.getWalletAddress(),
        data: this.erc20Contract.methods.totalSupply().encodeABI(),
      },
    ];

    return convertFromWei(await call(this.provider, params));
  }

  async approve(walletAddress, spenderAddress, tokenAddress, amount) {
    if (!this.erc20Contract) {
      console.error("erc20Contract is null in approve()");
      console.trace();
      throw new Error("Contract initialization failed.\nPlease refresh the page and try again.\ncode : 8403");
    }

    const web3 = new Web3(this.provider);
    const gasPrice = await web3.eth.getGasPrice();

    const params = [
      {
        from: walletAddress,
        to: tokenAddress,
        gas: web3.utils.toHex(3000000),
        gasPrice: web3.utils.toHex(gasPrice),
        data: this.erc20Contract.methods.approve(spenderAddress, amount).encodeABI(),
      },
    ];

    return send(this.provider, params);
  }

  async allowance(walletAddress, spenderAddress, tokenAddress) {
    if (!this.erc20Contract) {
      console.error("erc20Contract is null in allowance()");
      console.trace();
      throw new Error("Contract initialization failed.\nPlease refresh the page and try again.\ncode : 8405");
    }

    const params = [
      {
        to: tokenAddress,
        from: walletAddress,
        data: this.erc20Contract.methods.allowance(walletAddress, spenderAddress).encodeABI(),
      },
    ];

    return convertFromWei(await call(this.provider, params));
  }

  async increaseAllowance(walletAddress, spenderAddress, tokenAddress, amount) {
    if (!this.erc20Contract) {
      console.error("erc20Contract is null in increaseAllowance()");
      console.trace();
      throw new Error("Contract initialization failed.\nPlease refresh the page and try again.\ncode : 8407");
    }

    const web3 = new Web3(this.provider);
    const gasPrice = await web3.eth.getGasPrice();

    const params = [
      {
        from: walletAddress,
        to: tokenAddress,
        gas: web3.utils.toHex(3000000),
        gasPrice: web3.utils.toHex(gasPrice),
        data: this.erc20Contract.methods.increaseAllowance(spenderAddress, amount).encodeABI(),
      },
    ];

    return send(this.provider, params);
  }
}

export default ERC20Adapter;
