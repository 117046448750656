import Web3 from "web3";

import { useEffect, useState } from "react";
import { IskraSDK, LoginOptionBuilder } from "@iskraworld/iskra-sdk";
import normaServerClient from "../../../api/grampus";
import { getShortenedAddress } from "../../../../utility/helpers";
import ERC20Adapter from "./web3/erc20";
import ERC721Adapter from "./web3/erc721";
import StakingAdapter from "./web3/staking";
import ExchangeAdapter from "./web3/exchange";

import { createContext, useContext } from "react";

export const IskraContextV2 = createContext({});

export function useIskraV2() {
  return useContext(IskraContextV2);
}

const chainDataMap = {
  1: {
    chainId: 1,
    name: "Ethereum",
    rpcUrl: "https://mainnet.infura.io",
    scopeUrl: "https://etherscan.io/",
    symbol: "ETH",
    decimals: 18,
  },
  11155111: {
    chainId: 11155111,
    name: "Sepolia",
    rpcUrl: "https://sepolia.infura.io",
    scopeUrl: "https://sepolia.etherscan.io",
    symbol: "SepoliaETH",
    decimals: 18,
  },
  8453: {
    chainId: 8453,
    name: "Base",
    rpcUrl: "https://base-mainnet.infura.io",
    scopeUrl: "https://basescan.org",
    symbol: "ETH",
    decimals: 18,
  },
  84532: {
    chainId: 84532,
    name: "Base Sepolia",
    rpcUrl: "https://sepolia.base.org",
    scopeUrl: "https://sepolia.basescan.org/",
    symbol: "ETH",
    decimals: 18,
  },
  8217: {
    chainId: 8217,
    name: "Kaia",
    rpcUrl: "https://public-en.node.kaia.io",
    scopeUrl: "https://kaiascan.io",
    symbol: "KAIA",
    decimals: 18,
  },
  1001: {
    chainId: 1001,
    name: "Kaia Testnet",
    rpcUrl: "https://public-en-kairos.node.kaia.io",
    scopeUrl: "https://kairos.kaiascope.com",
    symbol: "KAIA",
    decimals: 18,
  },
};

const chainMap = {
  eth: {
    mainnet: 1,
    testnet: 11155111,
  },
  base: {
    mainnet: 8453,
    testnet: 84532,
  },
  kaia: {
    mainnet: 8217,
    testnet: 1001,
  },
};

export const IskraProviderV2 = ({ children }) => {
  const [contractLoading, setContractLoading] = useState(true);
  const [balanceLoading, setBalanceLoading] = useState(true);

  const [userData, setUserData] = useState(null);
  const [walletAddress, setWalletAddress] = useState();

  // contractDataMap["1001/candy"] = {Id: 1, Category: "candy", ChainId: 1001, Address: "0xFbe200dfBB8B795e64Ccba4a27C7Bd05d23Fdc2e", DisplayOrder: 1, IsToken: 1}
  const [contractDataMap, setContractDataMap] = useState(new Map());
  // chainTokenList = [{chainId: 1001, chainName: "Kaia Testnet", addressList: ["0xFbe200dfBB8B795e64Ccba4a27C7Bd05d23Fdc2e"], contractList: [{Id: 1, Category: "candy", ChainId: 1001, Address: "0xFbe200dfBB8B795e64Ccba4a27C7Bd05d23Fdc2e", DisplayOrder: 1, IsToken: 1}]}]
  const [chainTokenList, setChainTokenList] = useState([]);
  // balanceMap["1001/0xFbe200dfBB8B795e64Ccba4a27C7Bd05d23Fdc2e"] = {balanceInEther: "0", symbol: "GRAM"}
  const [balanceMap, setBalanceMap] = useState(new Map());

  const [iskraProvider, setIskraProvider] = useState(null);
  const [walletType, setWalletType] = useState(null);
  const [provider, setProvider] = useState(null);

  const [adapterMap, setAdapterMap] = useState(new Map());

  useEffect(() => {
    const appId = process.env.REACT_APP_ISKRA_APP_ID;
    const phase = process.env.REACT_APP_ISKRA_PHASE;

    IskraSDK.instance()
      .initializeAsync(phase, appId)
      .then(async () => {
        if (!isLoginExpired()) {
          login(true).catch(console.log);
        }
      })
      .catch((error) => {
        console.error("IskraSDK.initializeAsync() failed, error = " + error.message);
      });

    normaServerClient
      .getContractList()
      .then((response) => {
        /*
        [
          {
              "Id": 1,
              "Category": "candy",
              "ChainId": 1001,
              "Address": "0xFbe200dfBB8B795e64Ccba4a27C7Bd05d23Fdc2e",
              "DisplayOrder": 1,
              "IsToken": 1
          },
          {
              "Id": 2,
              "Category": "exchange",
              "ChainId": 1001,
              "Address": "0xd2E72ADcdd82E687158541fE196d53ED60CAaC09",
              "DisplayOrder": 0,
              "IsToken": 0
          },
          {
              "Id": 3,
              "Category": "exchange_pool",
              "ChainId": 1001,
              "Address": "0x95eB0F46a946e94d77dD0fdCEd75FB4A87E7456E",
              "DisplayOrder": 0,
              "IsToken": 0
          },
          {
              "Id": 4,
              "Category": "expgram",
              "ChainId": 1001,
              "Address": "0xb714a583fea70da755e580ae03765ed06a9c6ceb",
              "DisplayOrder": 0,
              "IsToken": 1
          },
          {
              "Id": 5,
              "Category": "gram",
              "ChainId": 1001,
              "Address": "0xb714a583fea70da755e580ae03765ed06a9c6ceb",
              "DisplayOrder": 0,
              "IsToken": 1
          },
          {
              "Id": 6,
              "Category": "isk",
              "ChainId": 1001,
              "Address": "0xb714a583fea70da755e580ae03765ed06a9c6ceb",
              "DisplayOrder": 0,
              "IsToken": 1
          },
          {
              "Id": 7,
              "Category": "nft",
              "ChainId": 1001,
              "Address": "0x74D7DfD6Cfe6D90e8dbD65c9B3C19A25dAB84001",
              "DisplayOrder": 0,
              "IsToken": 0
          },
          {
              "Id": 8,
              "Category": "ocs",
              "ChainId": 1001,
              "Address": "0x3E18A91F390e202904202521f9197181e7C9f967",
              "DisplayOrder": 0,
              "IsToken": 0
          },
          {
              "Id": 9,
              "Category": "staking",
              "ChainId": 1001,
              "Address": "0x5b53275f584f75a8869260fd33d67379009afe5f",
              "DisplayOrder": 0,
              "IsToken": 1
          },
          {
              "Id": 10,
              "Category": "gram",
              "ChainId": 59141,
              "Address": "0x72fedb53909eF45E37d66209c3AB718a0b975EaB",
              "DisplayOrder": 6,
              "IsToken": 1
          },
          {
              "Id": 11,
              "Category": "gram",
              "ChainId": 11155111,
              "Address": "0x72fedb53909eF45E37d66209c3AB718a0b975EaB",
              "DisplayOrder": 2,
              "IsToken": 1
          },
          {
              "Id": 12,
              "Category": "staking",
              "ChainId": 11155111,
              "Address": "0x0",
              "DisplayOrder": 3,
              "IsToken": 1
          },
          {
              "Id": 13,
              "Category": "jelly",
              "ChainId": 11155111,
              "Address": "0x7E4B14b0A7f8c6F8825EB41A3B6746ba49f3Ee3B",
              "DisplayOrder": 4,
              "IsToken": 1
          },
          {
              "Id": 14,
              "Category": "usdc",
              "ChainId": 11155111,
              "Address": "0xcD0C2D05Caa51AF8e93F6C2f1C21f983E084c832",
              "DisplayOrder": 5,
              "IsToken": 1
          }
      ]
      */
        const newCntractDataMap = new Map();
        response.data.forEach((contract) => {
          const key = contract.ChainId + "/" + contract.Category;
          newCntractDataMap.set(key, contract);
        });

        if (process.env.REACT_APP_ENV !== "production") {
          console.log("contractDataMap", newCntractDataMap);
        }

        setContractDataMap(newCntractDataMap);
        updateBalance();
      })
      .catch((error) => {
        console.log(error);
      });

    const iskraInterface = {
      getWalletAddress: () => walletAddress,
    };

    const newAdapterMap = new Map();
    newAdapterMap.set("erc20", new ERC20Adapter(iskraInterface));
    newAdapterMap.set("erc721", new ERC721Adapter(iskraInterface));
    newAdapterMap.set("staking", new StakingAdapter(iskraInterface));
    newAdapterMap.set("exchange", new ExchangeAdapter(iskraInterface));

    newAdapterMap.forEach((adapter) => {
      adapter.loadAbis();
    });

    setAdapterMap(newAdapterMap);
  }, []);

  useEffect(() => {
    adapterMap.forEach((adapter) => {
      adapter.iniProvider(provider);
    });
  }, [iskraProvider, adapterMap]);

  useEffect(() => {
    const newChainTokenList = [];

    contractDataMap.forEach((value) => {
      if (value.IsToken === 1 && value.Address != "0x0" && value.DisplayOrder > 0) {
        let chain = newChainTokenList.find((chain) => chain.chainId === value.ChainId);
        if (!chain) {
          chain = { chainId: value.ChainId, chainName: chainDataMap[value.ChainId].name, addressList: [], contractList: [] };
          newChainTokenList.push(chain);
        }
        chain.addressList.push(value.Address);
        chain.contractList.push(value);
      }
    });

    newChainTokenList.forEach((chain) => {
      chain.contractList.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    });

    if (process.env.REACT_APP_ENV !== "production") {
      console.log("chainTokenList", newChainTokenList);
    }
    setChainTokenList(newChainTokenList);
  }, [contractDataMap]);

  useEffect(() => {
    if (adapterMap.size == 4 && contractDataMap.size > 0 && chainTokenList.length > 0) {
      console.log("loading complete");
      setContractLoading(false);
    }
  }, [adapterMap, contractDataMap, chainTokenList]);

  useEffect(() => {
    if (walletAddress && chainTokenList.length > 0) {
      updateBalance();
    }
  }, [walletAddress, chainTokenList, iskraProvider]);

  const isLoginExpired = () => {
    const loginExpiredTime = localStorage.getItem("loginExpiredTime");
    if (loginExpiredTime && loginExpiredTime > Date.now()) {
      return false;
    }
    return true;
  };

  const setExpiredTime = () => {
    const expiredTime = Date.now() + 1000 * 60 * 60 * 24; // 1 day
    localStorage.setItem("loginExpiredTime", expiredTime);
  };

  const resetExpiredTime = () => {
    localStorage.removeItem("loginExpiredTime");
  };

  const updateBalance = async () => {
    if (!iskraProvider) {
      setBalanceMap(new Map());
      return;
    }
    setBalanceLoading(true);

    const web3 = new Web3();

    const newBalanceMap = new Map();
    await Promise.all(
      chainTokenList.map(async (chainToken) => {
        try {
          /*
        {
            "baseCoinBalance": {
                "symbol": "ETH",
                "balance": "0",
                "decimal": 18
            },
            "tokenBalances": [
                {
                    "symbol": "Gram",
                    "contractAddress": "0x188dB626d2F9B53cB4A2f4A6313Ba918A3960D36",
                    "balance": "0",
                    "decimal": 18
                },
                {
                    "symbol": "Jelly",
                    "contractAddress": "0x43c878904BEDE417A491c65Bdf812957453F8a91",
                    "balance": "321123456000000000000",
                    "decimal": 18
                },
                {
                    "symbol": "USDT",
                    "contractAddress": "0xEa9404E41E80a49D4F910b4cB06c6B0AE1307e89",
                    "balance": "0",
                    "decimal": 6
                }
            ]
        }
        */
          const balancesRet = await IskraSDK.instance().getBalances(chainToken.chainId, chainToken.addressList);
          const baseCoinBalance = web3.utils.fromWei(balancesRet.baseCoinBalance.balance, "ether");
          const baseCoinKey = chainToken.chainId + "/0x0";
          newBalanceMap.set(baseCoinKey, { balanceInEther: baseCoinBalance, symbol: balancesRet.baseCoinBalance.symbol });

          balancesRet.tokenBalances.forEach((tokenBalance) => {
            let balanceInEther;
            if (tokenBalance.decimal === 18) {
              balanceInEther = web3.utils.fromWei(tokenBalance.balance, "ether");
            } else {
              const balanceInWei = web3.utils.toBN(tokenBalance.balance);
              const decimalFactor = web3.utils.toBN(10).pow(web3.utils.toBN(tokenBalance.decimal));
              balanceInEther = (balanceInWei.toNumber() / decimalFactor.toNumber()).toFixed(tokenBalance.decimal);
            }
            const key = chainToken.chainId + "/" + tokenBalance.contractAddress.toLowerCase();
            newBalanceMap.set(key, { balanceInEther, symbol: tokenBalance.symbol });
          });
        } catch (error) {
          console.error(error);
          // insert dummy data
          if (process.env.REACT_APP_ENV !== "production") {
            newBalanceMap.set(chainToken.chainId + "/0x0", { balanceInEther: Math.random() * 100, symbol: chainDataMap[chainToken.chainId].symbol });
            chainToken.contractList.forEach((contractInfo) => {
              const key = contractInfo.ChainId + "/" + contractInfo.Address.toLowerCase();
              newBalanceMap.set(key, { balanceInEther: Math.random() * 100, symbol: contractInfo.Category });
            });
          }
        }
      })
    );

    if (process.env.REACT_APP_ENV !== "production") {
      console.log("balanceMap", newBalanceMap);
    }
    setBalanceMap(newBalanceMap);
  };

  useEffect(() => {
    setBalanceLoading(balanceMap.size === 0);
  }, [balanceMap]);

  function handleAccountsChanged(accounts) {
    setWalletAddress(accounts[0]);
    // console.log("handleAccountsChanged:" + accounts[0]);
  }

  const changeNetwork = async (chainId) => {
    let currentChainId;
    if (walletType === "iskra") {
      currentChainId = IskraSDK.instance().getAppChainId();

      if (currentChainId === chainId) {
        return true;
      }

      const result = await IskraSDK.instance().switchOrAddChain(chainId);
      if (result) {
        console.log("Failed to switch chain : " + chainId);
        console.log(result);
      }

      return !result;
    }

    currentChainId = await provider.request({ method: "eth_chainId" });
    const targetChainData = chainDataMap[chainId];
    if (process.env.REACT_APP_ENV !== "production") {
      console.log("currentChainId", currentChainId);
      console.log("targetChainData", targetChainData.chainId);
    }

    if (Number(currentChainId) !== Number(targetChainData.chainId)) {
      // add network
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${targetChainData.chainId.toString(16)}`,
              chainName: targetChainData.name,
              nativeCurrency: {
                name: targetChainData.symbol,
                symbol: targetChainData.symbol,
                decimals: targetChainData.decimals,
              },
              rpcUrls: [targetChainData.rpcUrl],
              blockExplorerUrls: [targetChainData.scopeUrl],
            },
          ],
        });
      } catch (error) {
        console.error(error);
        return false;
      }

      // change network
      try {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${targetChainData.chainId.toString(16)}` }],
        });
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    let selectedAccounts = await provider.request({ method: "eth_accounts" });

    if (!walletAddress) {
      await requestAccountsFromIskra();
      return false;
    }

    if (!selectedAccounts || selectedAccounts.length <= 0 || walletAddress.toLowerCase() !== selectedAccounts[0].toLowerCase()) {
      try {
        if (walletType === "metamask" && selectedAccounts && selectedAccounts.length > 0) {
          await provider.request({
            method: "wallet_requestPermissions",
            params: [
              {
                eth_accounts: {},
              },
            ],
          });
        }

        selectedAccounts = await provider.request({
          method: "eth_requestAccounts",
          params: [
            {
              eth_accounts: {},
            },
          ],
        });
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    if (!selectedAccounts || selectedAccounts.length <= 0 || walletAddress.toLowerCase() !== selectedAccounts[0].toLowerCase()) {
      throw new Error("The wallet address linked to the Iskra account is different from the currently linked wallet address. Please connect to the wallet below and try again.\n Address : " + getShortenedAddress(walletAddress));
    }

    return true;
  };

  const requestAccountsFromIskra = async () => {
    let p = iskraProvider;
    if (!p) {
      const newIskraProvider = await IskraSDK.instance().getIskraWalletProvider();

      setIskraProvider(newIskraProvider);

      p = newIskraProvider;
    }

    const walletAddress = await p.request({
      method: "eth_requestAccounts",
      params: [
        {
          eth_accounts: {},
        },
      ],
    });

    handleAccountsChanged(walletAddress);
  };

  const setLoginData = async (data, resolve, reject) => {
    const { provider, error, type } = await IskraSDK.instance().getWalletProvider();
    const iskraProvider = await IskraSDK.instance().getIskraWalletProvider();

    if (error) {
      resetExpiredTime();
      reject(error.message);
      return;
    }

    setIskraProvider(iskraProvider);
    setProvider(provider);
    setWalletType(type);
    setUserData(data);

    if (process.env.REACT_APP_ENV !== "production") {
      console.log("iskra provider", iskraProvider);
      console.log("provider", provider);
      console.log("wallet type", type);
      console.log(data);
    }

    requestAccountsFromIskra();
    resolve();
  };

  const login = (sticky = false) =>
    new Promise((resolve, reject) => {
      if (isLoginExpired()) {
        // When login is expired, login again
        const loginOption = new LoginOptionBuilder()
          .enableIskraWallet() // If a wallet creation is needed from the beginning
          .build();
        IskraSDK.instance().login(loginOption, async (data, error) => {
          if (error != null) {
            reject(error.message);
          } else {
            setLoginData(data, resolve, reject);
            setExpiredTime();
          }
        });
      } else {
        IskraSDK.instance().loadLastLogin(async (data, error) => {
          if (error != null) {
            // has no last login data
            if (sticky) {
              resetExpiredTime();
              return;
            }

            const loginOption = new LoginOptionBuilder()
              .enableIskraWallet() // If a wallet creation is needed from the beginning
              .build();
            IskraSDK.instance().login(loginOption, async (data, error) => {
              if (error != null) {
                reject(error.message);
              } else {
                setLoginData(data, resolve, reject);
                setExpiredTime();
              }
            });
          } else {
            // has last login data
            setLoginData(data, resolve, reject);
          }
        });
      }
    });

  const logout = () => {
    setUserData(null);
    setWalletAddress("");
    resetExpiredTime();
  };

  const getBalanceByName = (chainName, symbol) => {
    const isProductMode = process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging";
    const contractInfo = getContractInfo(chainName, symbol);
    const key = chainMap[chainName][isProductMode ? "mainnet" : "testnet"] + "/" + contractInfo?.Address.toLowerCase();

    if (!balanceMap.has(key)) {
      return 0;
    }

    return balanceMap.get(key)?.balanceInEther || 0;
  };

  const getBalanceInfo = (chainId, address) => {
    const key = chainId + "/" + address.toLowerCase();

    if (!balanceMap.has(key)) {
      return 0;
    }

    return balanceMap.get(key) || { balanceInEther: 0, symbol: "" };
  };

  const getContractInfo = (chainName, symbol) => {
    const isProductMode = process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging";
    const key = chainMap[chainName][isProductMode ? "mainnet" : "testnet"] + "/" + symbol;

    if (!contractDataMap.has(key)) {
      return null;
    }

    // {Id: 1, Category: "candy", ChainId: 1001, Address: "0xFbe200dfBB8B795e64Ccba4a27C7Bd05d23Fdc2e", DisplayOrder: 1, IsToken: 1}
    return contractDataMap.get(key) || null;
  };

  return (
    <IskraContextV2.Provider
      value={{
        login,
        logout,
        requestAccountsFromIskra,
        erc20Adapter: adapterMap.get("erc20"),
        erc721Adapter: adapterMap.get("erc721"),
        stakingAdapter: adapterMap.get("staking"),
        exchangeAdapter: adapterMap.get("exchange"),
        getBalanceInfo,
        getBalanceByName,
        getContractInfo,
        updateBalance,
        changeNetwork,
        contractLoading,
        balanceLoading,
        chainTokenList,
        userData,
        walletAddress,
      }}
    >
      {children}
    </IskraContextV2.Provider>
  );
};
