import Web3Adapter from "../adapter";
import ERC721Abi from "../../../../../../assets/contract/ERC721.abi";
import { call } from "../../module";
import { convertFromWei } from "../../../../../../utility/helpers";

class ERC721Adapter extends Web3Adapter {
  async loadAbis() {
    super.loadAbis();

    const res = await fetch(ERC721Abi);
    const data = await res.text();

    this.erc721Contract = new this.web3.eth.Contract(JSON.parse(data));
  }

  async balanceOf(walletAddress, contractAddress) {
    if (!this.erc721Contract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: walletAddress,
        data: this.erc721Contract.methods.balanceOf(walletAddress).encodeABI(),
      },
    ];

    return new Promise((resolve, reject) => {
      this.provider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async totalSupply(contractAddress) {
    if (!this.erc721Contract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: this.iskraInterface.getWalletAddress(),
        data: this.erc721Contract.methods.totalSupply().encodeABI(),
      },
    ];

    return convertFromWei(await call(this.provider, params));
  }

  async tokenOfOwnerByIndex(walletAddress, contractAddress, index) {
    if (!this.erc721Contract) {
      return -1;
    }

    const params = [
      {
        to: contractAddress,
        from: walletAddress,
        data: this.erc721Contract.methods.tokenOfOwnerByIndex(walletAddress, index).encodeABI(),
      },
    ];

    return new Promise((resolve, reject) => {
      this.provider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(this.web3.utils.toNumber(result));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async nftIdsList(walletAddress, contractAddress) {
    return new Promise(async (resolve, reject) => {
      this.balanceOf(walletAddress, contractAddress)
        .then((res) => {
          const balance = this.web3.utils.toNumber(res);
          if (balance === 0) resolve([]);

          const nftIds = [];
          for (let i = 0; i < balance; i++) {
            this.tokenOfOwnerByIndex(walletAddress, contractAddress, i)
              .then((res) => {
                if (res === -1) {
                  resolve([]);
                }

                nftIds.push(res);
                if (nftIds.length === balance) {
                  resolve(nftIds.sort((a, b) => a - b));
                }
              })
              .catch((err) => {
                reject(err);
              });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default ERC721Adapter;
