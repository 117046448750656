import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./index.module.scss";
import { getShortenedBalance, displayBalanceFloat, numberWithCommas, getShortenedBalanceDigit, displayBalanceWei, convertFromWei, converToWei, sleep, floorBalance } from "../../../../utility/helpers";
import { ReactComponent as QuestionIcon } from "../../../../assets/images/homepage/question.svg";
import { Tooltip } from "react-tooltip";
import normaServerClient from "../../../../module/api/grampus/index";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import Modal from "../../../elements/modal/Modal";
import { useIskraV2 } from "../../../../module/hook/v2/iskra";
import { Loading } from "../../../elements/loading/Loading";

export const Staking = () => {
  const { login, walletAddress, updateBalance, contractLoading, stakingAdapter, userData, getBalanceByName, getContractInfo, changeNetwork } = useIskraV2();

  const [gramContractInfo, setGramContractInfo] = useState(null);
  const [stakingContractInfo, setStakingContractInfo] = useState(null);

  const [stakingAmount, setStakingAmount] = useState(0);
  const [unstakingAmount, setUnstakingAmount] = useState(0);
  const [stakingRate, setStakingRate] = useState(1);
  const [unstakingRate, setUnstakingRate] = useState(1);
  const [stakingTxFee, setStakingTxFee] = useState(0);
  const [unstakingTxFee, setUnstakingTxFee] = useState(0);
  const [isStaking, setIsStaking] = useState(false);
  const [apy, setApy] = useState(0);
  const [rewardAmount, setRewardAmount] = useState(0);
  const [lastUnstakingRate, setLastUnstakingRate] = useState(0);
  const [totalPooledSgram, setTotalPooledSgram] = useState(0);
  const [totalPooledGram, setTotalPooledGram] = useState(0);

  const [isTxProcessing, setIsTxProcessing] = useState(false);
  const [visibleModalChecking, setVisibleModalChecking] = useState(false);

  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContents, setModalContents] = useState("");

  const [updateRemainCount, setUpdateRemainCount] = useState(0);

  useEffect(() => {
    if (contractLoading) {
      return;
    }

    if (walletAddress && gramContractInfo && stakingContractInfo && stakingAmount > 0) {
      changeNetwork(gramContractInfo.ChainId).then(() => {
        stakingAdapter.getEstimatedStakingGasFee(walletAddress, stakingContractInfo.Address, gramContractInfo.Address, converToWei(stakingAmount)).then((res) => {
          setStakingTxFee(res);
        });
      });
    } else {
      setStakingTxFee(0);
    }
  }, [gramContractInfo, stakingContractInfo, walletAddress, stakingAmount, contractLoading, stakingAdapter]);

  useEffect(() => {
    if (contractLoading) {
      return;
    }

    if (walletAddress && gramContractInfo && stakingContractInfo && unstakingAmount > 0) {
      changeNetwork(gramContractInfo.ChainId).then(() => {
        stakingAdapter.getEstimatedUnstakingGasFee(walletAddress, stakingContractInfo.Address, gramContractInfo.Address, converToWei(unstakingAmount)).then((res) => {
          setUnstakingTxFee(res);
        });
      });
    } else {
      setUnstakingTxFee(0);
    }
  }, [gramContractInfo, stakingContractInfo, walletAddress, unstakingAmount, contractLoading, stakingAdapter]);

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();

    updateStakingInfo();
  }, []);

  useEffect(() => {
    if (contractLoading) {
      return;
    }

    setGramContractInfo(getContractInfo("base", "gram"));
    setStakingContractInfo(getContractInfo("base", "staking"));
  }, [contractLoading]);

  function updateStakingInfo() {
    normaServerClient.getStakingInfo().then((res) => {
      const data = res.data;
      setLastUnstakingRate(data.lastRate.Rate);
      setStakingRate(data.convertRateOriginToStaking);
      setUnstakingRate(data.convertRateStakingToOrigin);
      setTotalPooledGram(data.totalPooledOrigin);
      setTotalPooledSgram(data.totalSupply);

      const timeDiffSec = Math.floor(new Date().getTime() / 1000 - data.startInflationFromTimestamp);
      const inflationTotalAmount = timeDiffSec * data.inflationPerSec;
      setRewardAmount(inflationTotalAmount);

      setApy(data.expectedEarning * 31536000);
    });
  }

  function showStakingModal() {
    setIsStaking(true);
    setVisibleModalChecking(true);
  }

  function showUnstakingModal() {
    setIsStaking(false);
    setVisibleModalChecking(true);
  }

  function closeCheckingModal() {
    setVisibleModalChecking(false);
  }

  async function runStakeTransaction() {
    setIsTxProcessing(true);

    try {
      await login();
    } catch (e) {
      finishTx("Error", e.message);
      console.log(e);
      return;
    }

    try {
      await changeNetwork(stakingContractInfo.ChainId);
    } catch (e) {
      finishTx("Error", e.message);
      console.log(e);
      return;
    }

    let myGramAmountWei = 0;
    try {
      myGramAmountWei = await stakingAdapter.balanceOf(walletAddress, gramContractInfo.Address);
    } catch (e) {
      finishTx("Error", e.message);
      console.log(e);
      return;
    }

    if (Number(convertFromWei(myGramAmountWei)) < Number(stakingAmount)) {
      finishTx("Error", "You don't have enough GRAM to stake.");
      return;
    }

    let allowanceEth = 0;
    try {
      allowanceEth = await stakingAdapter.allowance(walletAddress, stakingContractInfo.Address, gramContractInfo.Address);
    } catch (e) {
      finishTx("Error", e.message);
      console.log(e);
      return;
    }

    if (Number(allowanceEth) < Number(stakingAmount) + 0.0001) {
      try {
        await stakingAdapter.increaseAllowance(walletAddress, stakingContractInfo.Address, gramContractInfo.Address, converToWei(Number(stakingAmount) - Number(allowanceEth) + 0.0001));
        await sleep(15000);
      } catch (e) {
        finishTx("Error", e.message);
        console.log(e);
        return;
      }
    }

    let txHash;
    try {
      txHash = await stakingAdapter.stake(walletAddress, stakingContractInfo.Address, converToWei(stakingAmount));
    } catch (e) {
      finishTx("Error", e.message);
      console.log(e);
      return;
    }

    if (!txHash) {
      finishTx("Error", "Failed to stake.");
      return;
    }

    try {
      await normaServerClient.addStakingItem(userData?.accessToken, 0, txHash, stakingContractInfo.Address, stakingContractInfo.ChainId);
    } catch (e) {
      console.log(e);
    }

    await sleep(10000);
    finishTx("Success", "Stake succeeded! Check your wallet.");
    resetStatus();
  }

  async function runUnstakeTransaction() {
    setIsTxProcessing(true);

    try {
      await login();
    } catch (e) {
      finishTx("Error", e.message);
      console.log(e);
      return;
    }

    try {
      await changeNetwork(stakingContractInfo.ChainId);
    } catch (e) {
      finishTx("Error", e.message);
      console.log(e);
      return;
    }

    let mySGramAmountWei = 0;
    try {
      mySGramAmountWei = await stakingAdapter.balanceOf(walletAddress, stakingContractInfo.Address);
    } catch (e) {
      finishTx("Error", e.message);
      console.log(e);
      return;
    }

    if (Number(convertFromWei(mySGramAmountWei)) < Number(unstakingAmount)) {
      finishTx("Error", "You don't have enough sGRAM to unstake.");
      return;
    }

    let allowanceEth = 0;
    try {
      allowanceEth = await stakingAdapter.allowance(walletAddress, stakingContractInfo.Address, stakingContractInfo.Address);
    } catch (e) {
      finishTx("Error", e.message);
      console.log(e);
      return;
    }

    if (Number(allowanceEth) < Number(unstakingAmount) + 0.0001) {
      try {
        await stakingAdapter.approve(walletAddress, stakingContractInfo.Address, stakingContractInfo.Address, converToWei(Number(unstakingAmount) - Number(allowanceEth) + 0.0001));
        await sleep(15000);
      } catch (e) {
        finishTx("Error", e.message);
        console.log(e);
        return;
      }
    }

    let txHash;
    try {
      txHash = await stakingAdapter.withdraw(walletAddress, stakingContractInfo.Address, converToWei(unstakingAmount));
    } catch (e) {
      finishTx("Error", e.message);
      console.log(e);
      return;
    }

    if (!txHash) {
      finishTx("Error", "Failed to unstake.");
      return;
    }

    try {
      await normaServerClient.addStakingItem(userData?.accessToken, 1, txHash, stakingContractInfo.Address, stakingContractInfo.ChainId);
    } catch (e) {
      console.log(e);
    }

    await sleep(10000);
    finishTx("Success", "Unstake succeeded! Check your wallet.");
    resetStatus();
  }

  function getRateChange() {
    return ((unstakingRate - lastUnstakingRate) / lastUnstakingRate) * 100;
  }

  function resetStatus() {
    setUpdateRemainCount(20);
    updateStakingInfo();
    setStakingAmount(0);
    setUnstakingAmount(0);
    setStakingTxFee(0);
    setUnstakingTxFee(0);
  }

  function finishTx(modalTitle, modalContents) {
    setIsTxProcessing(false);
    setVisibleModalChecking(false);
    setVisibleModalConfirm(true);
    setModalTitle(modalTitle);
    setModalContents(modalContents);
  }

  useEffect(() => {
    if (updateRemainCount > 0) {
      setTimeout(() => {
        updateBalance();

        setUpdateRemainCount(updateRemainCount - 1);
      }, 1000);
    }
  }, [updateRemainCount]);

  return (
    <main className={styles.main}>
      <section className={styles.section}>
        <div className={styles.background}>
          <img className={styles.pc_img} src={require("../../../../assets/images/homepage/staking_bg.png")} alt="bg" />
          <img className={cn(styles.mobile_img, styles.mobile_img_1)} src={require("../../../../assets/images/homepage/exchange_mobile_bg1.png")} alt="bg" />
          <img className={cn(styles.mobile_img, styles.mobile_img_2)} src={require("../../../../assets/images/homepage/exchange_mobile_bg3.png")} alt="bg" />
          <img className={cn(styles.mobile_img, styles.mobile_img_3)} src={require("../../../../assets/images/homepage/staking_mobile_bg1.png")} alt="bg" />
        </div>
        <div className={cn("container", styles.container)}>
          <div className={styles.title}>Staking</div>
          <div className={styles.content}>Stake GRAM and receive sGRAM!</div>
          <div className={styles.staking_control_container}>
            <div className={styles.staking_control_box}>
              <div className={styles.input_box}>
                <div className={styles.balance_row}>
                  <span className={styles.balance}>{displayBalanceFloat(getBalanceByName("base", "gram"))}</span>
                  <button className={styles.max_button} onClick={() => setStakingAmount(floorBalance(getBalanceByName("base", "gram"), 4))}>
                    MAX
                  </button>
                </div>
                <div className={styles.input_row}>
                  <div className={styles.icon}>
                    <img src={require("../../../../assets/images/homepage/staking_gram_icon.png")} alt="gram" />
                  </div>
                  <span className={styles.name}>GRAM</span>
                  <input className={styles.input} type="number" value={stakingAmount ? getShortenedBalance(stakingAmount) : ""} onChange={(e) => setStakingAmount(e.target.value)} placeholder="0.0000" />
                </div>
              </div>
              <div className={styles.info_box}>
                <div className={styles.info_row}>
                  <span className={styles.label}>You will receive</span>
                  <img className={styles.icon} src={require("../../../../assets/images/homepage/staking_sgram_small_icon.png")} alt="sgram" />
                  <span className={styles.value}>{displayBalanceFloat(stakingAmount * stakingRate)} sGRAM</span>
                </div>
                <div className={styles.info_row}>
                  <span className={styles.label}>Exchange Rate</span>
                  <span className={styles.value}>1 Gram = {displayBalanceFloat(stakingRate)} sGRAM</span>
                </div>
                <div className={styles.info_row}>
                  <span className={styles.label}>Estimatied Transaction Fee</span>
                  <img className={styles.icon_base} src={require("../../../../assets/images/homepage/staking_base_eth_icon.png")} alt="klaytn" />
                  <span className={styles.value}>{displayBalanceWei(stakingTxFee, 8)} ETH</span>
                </div>
              </div>
              <div className={styles.control_box}>
                <button className={cn(styles.stake_button, { [styles.disabled]: stakingAmount == 0 || !walletAddress })} onClick={() => showStakingModal()}>
                  Stake
                </button>
              </div>
            </div>
            <div className={styles.staking_control_box}>
              <div className={styles.input_box}>
                <div className={styles.balance_row}>
                  <span className={styles.balance}>{displayBalanceFloat(getBalanceByName("base", "staking"))}</span>
                  <button className={styles.max_button} onClick={() => setUnstakingAmount(floorBalance(getBalanceByName("base", "staking"), 4))}>
                    MAX
                  </button>
                </div>
                <div className={styles.input_row}>
                  <div className={styles.icon}>
                    <img src={require("../../../../assets/images/homepage/staking_sgram_icon.png")} alt="sgram" />
                  </div>
                  <span className={styles.name}>sGRAM</span>
                  <input className={styles.input} type="number" value={unstakingAmount ? getShortenedBalance(unstakingAmount) : ""} onChange={(e) => setUnstakingAmount(e.target.value)} placeholder="0.0000" />
                </div>
              </div>
              <div className={styles.info_box}>
                <div className={styles.info_row}>
                  <span className={styles.label}>You will receive</span>
                  <img className={styles.icon} src={require("../../../../assets/images/homepage/staking_gram_small_icon.png")} alt="gram" />
                  <span className={styles.value}>{displayBalanceFloat(unstakingAmount * unstakingRate)} GRAM</span>
                </div>
                <div className={styles.info_row}>
                  <span className={styles.label}>Exchange Rate</span>
                  <span className={styles.value}>1 sGram = {displayBalanceFloat(unstakingRate)} GRAM</span>
                </div>
                <div className={styles.info_row}>
                  <span className={styles.label}>Estimatied Transaction Fee</span>
                  <img className={styles.icon_base} src={require("../../../../assets/images/homepage/staking_base_eth_icon.png")} alt="klaytn" />
                  <span className={styles.value}>{displayBalanceWei(unstakingTxFee, 8)} ETH</span>
                </div>
              </div>
              <div className={styles.control_box}>
                <button className={cn(styles.stake_button, { [styles.disabled]: unstakingAmount == 0 || !walletAddress })} onClick={() => showUnstakingModal()}>
                  Unstake
                </button>
              </div>
            </div>
          </div>
          <div className={styles.staking_info_container}>
            <div className={styles.info_title}>Status</div>
            <div className={styles.info_exp}>Values on statistics change in real time.</div>
            <div className={styles.staking_info_row}>
              <div className={styles.staking_info_upper_box}>
                <div className={styles.staking_info_box_title}>
                  <img className={styles.icon} src={require("../../../../assets/images/homepage/staking_gram_small_icon.png")} alt="gram" />
                  <span className={styles.name}>APY</span>
                  <QuestionIcon className={styles.question_icon} id="apy_question_icon" />
                </div>
                <div className={styles.staking_info_box_value}>
                  <span className={styles.apy_value}>{numberWithCommas(getShortenedBalanceDigit(apy * 100, 2))}%</span>
                </div>
              </div>
              <div className={styles.staking_info_upper_box}>
                <div className={styles.staking_info_box_title}>
                  <img className={styles.icon} src={require("../../../../assets/images/homepage/staking_gram_small_icon.png")} alt="gram" />
                  <span className={styles.name}>GRAM Rewards Paid</span>
                  <QuestionIcon className={styles.question_icon} id="reward_question_icon" />
                </div>
                <div className={styles.staking_info_box_value}>
                  <span className={styles.value}>{displayBalanceFloat(rewardAmount)} GRAM</span>
                </div>
              </div>
            </div>
            <div className={styles.staking_info_lower_box}>
              <div className={styles.staking_info_box_title}>
                <img className={styles.icon} src={require("../../../../assets/images/homepage/staking_pair_icon.png")} alt="pair" />
                <span className={styles.name}>sGRAM Value</span>
                <QuestionIcon className={styles.question_icon} id="rate_question_icon" />
              </div>
              <div className={styles.staking_info_box_value_mobile}>
                <span className={cn(styles.change_value, { [styles.down_value]: lastUnstakingRate < 0 })}>{getRateChange() < 0 ? "▼" : "▲"}</span>
                <span className={cn(styles.change_value, { [styles.down_value]: lastUnstakingRate < 0 })}>{displayBalanceFloat(Math.abs(getRateChange()))} %</span>
              </div>
              <div className={styles.staking_info_box_value}>
                <span className={styles.minor_value}>1 sGRAM</span>
                <span className={styles.symbol}>=</span>
                <span className={styles.value}>{displayBalanceFloat(unstakingRate)} GRAM</span>
                <span className={cn(styles.change_value, styles.change_value_pc, { [styles.down_value]: lastUnstakingRate < 0 })}>{getRateChange() < 0 ? "▼" : "▲"}</span>
                <span className={cn(styles.change_value, styles.change_value_pc, { [styles.down_value]: lastUnstakingRate < 0 })}>{displayBalanceFloat(Math.abs(getRateChange()))} %</span>
              </div>
              <div className={styles.staking_info_saparator} />
              <div className={styles.staking_info_box_title_row}>
                <div className={styles.staking_info_box_value_col}>
                  <div className={styles.staking_info_box_title}>
                    <img className={styles.icon} src={require("../../../../assets/images/homepage/staking_sgram_small_icon.png")} alt="sgram" />
                    <span className={styles.name}>Total sGRAM Circulating</span>
                    <QuestionIcon className={styles.question_icon} id="sgram_question_icon" />
                  </div>
                  <div className={cn(styles.staking_info_box_value_amount, styles.align_left)}>
                    <span className={styles.value}>{displayBalanceFloat(totalPooledSgram)} sGRAM</span>
                  </div>
                </div>
                <div className={styles.staking_info_box_value_col}>
                  <div className={styles.staking_info_box_title}>
                    <img className={styles.icon} src={require("../../../../assets/images/homepage/staking_gram_small_icon.png")} alt="gram" />
                    <span className={styles.name}>Total GRAM Staked</span>
                    <QuestionIcon className={styles.question_icon} id="gram_question_icon" />
                  </div>
                  <div className={styles.staking_info_box_value_amount}>
                    <span className={styles.value}>{displayBalanceFloat(totalPooledGram)} GRAM</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.exp_title}>The longer and the more, the better!</div>
          <div className={styles.exp}>
            - You need to stake earlier, more and longer to receive more staking rewards.
            <br />
            - How much GRAM staking rewards you will get is determined by how many sGRAM you have compared to the total number of sGRAM.
            <br />
            - To maximize your GRAM staking rewards, having a large number sGRAM would be helpful and even if you stake the same number of GRAM, staking as early as possible would lead to the maximum amount of sGRAM.
            <br />- sGRAM is issued according to the exchange rate between GRAM and sGRAM at the time of staking and this rate is structured to continuously decrease over time. Again, the earlier you stake your GRAM the more sGRAM you will receive!
          </div>
        </div>
      </section>

      <Tooltip anchorSelect="#apy_question_icon" className={styles.tooltip}>
        <div className={styles.tooltip_text}>
          APY
          <br />
          <br />
          APY (Annual Percentage Yield) is and indicator of the annual return rate through staking GRAM.
        </div>
      </Tooltip>

      <Tooltip anchorSelect="#reward_question_icon" className={styles.tooltip}>
        <div className={styles.tooltip_text}>
          GRAM Rewards Paid
          <br />
          <br />
          It refers to the cumulative total amount of rewards distributed from the GRAM Staking Rewards Pool. GRAM Staking Rewards are distributed every second.
        </div>
      </Tooltip>

      <Tooltip anchorSelect="#rate_question_icon" className={styles.tooltip}>
        <div className={styles.tooltip_text}>
          sGRAM Value
          <br />
          <br />
          It refers to the exchange value of 1 sGRAM. The value of sGRAM will rise in real time with the rate gradually decreasing as the GRAM rewards are also staked in the Staking pool with the compound interest rate. You may also see how the value of sGRAM in xreases over 24 hours.
        </div>
      </Tooltip>
      <Tooltip anchorSelect="#sgram_question_icon" className={styles.tooltip}>
        <div className={styles.tooltip_text}>
          Total sGRAM Circulating
          <br />
          <br />
          It is the sGRAM in circulation. This is the total amount of sGRAM minted excluding the burned sGRAM. sGRAM is minted at the time of staking and burned when unstaked.
        </div>
      </Tooltip>
      <Tooltip anchorSelect="#gram_question_icon" className={styles.tooltip}>
        <div className={styles.tooltip_text}>
          Total GRAM Staked
          <br />
          <br />
          It indicates the total amount of GRAM deposited in the staking pool. It fluctuates as users stake and unstake in real time.
        </div>
      </Tooltip>
      <Modal outerClassName={styles.check_modal} visible={visibleModalChecking} onClose={closeCheckingModal} unquenchable={isTxProcessing}>
        <div className={styles.check_title}>Check</div>
        <div className={styles.check_token_info_container}>
          <div className={styles.check_token_info_title}>{isStaking ? "Staking" : "Unstaking"}</div>
          <div className={styles.row}>
            <div className={cn(styles.col, styles.icon)}>{isStaking ? <img src={require("../../../../assets/images/homepage/staking_gram_icon.png")} alt="gram" /> : <img src={require("../../../../assets/images/homepage/staking_sgram_icon.png")} alt="sgram" />}</div>
            <div className={styles.mobile_row}>
              <div className={cn(styles.col, styles.symbol)}>{isStaking ? "GRAM" : "sGRAM"}</div>
              <div className={cn(styles.col, styles.amount)}>{isStaking ? displayBalanceFloat(stakingAmount) : displayBalanceFloat(unstakingAmount)}</div>
            </div>
          </div>
        </div>
        <div className={styles.check_token_info_container}>
          <div className={styles.check_token_info_title}>Receive</div>
          <div className={styles.row}>
            <div className={cn(styles.col, styles.icon)}>{!isStaking ? <img src={require("../../../../assets/images/homepage/staking_gram_icon.png")} alt="gram" /> : <img src={require("../../../../assets/images/homepage/staking_sgram_icon.png")} alt="sgram" />}</div>
            <div className={styles.mobile_row}>
              <div className={cn(styles.col, styles.symbol)}>{!isStaking ? "GRAM" : "sGRAM"}</div>
              <div className={cn(styles.col, styles.amount)}>{isStaking ? displayBalanceFloat(stakingAmount * stakingRate) : displayBalanceFloat(unstakingAmount * unstakingRate)}</div>
            </div>
          </div>
        </div>
        <div className={styles.check_token_more_info_container}>
          <div className={styles.check_token_more_info_row}>
            <span className={styles.check_token_more_info_label}>Exchange Rate</span>
            <span className={styles.check_token_more_info_value}>{isStaking ? "1 GRAM = " + displayBalanceFloat(stakingRate) + " sGRAM" : "1 sGRAM = " + displayBalanceFloat(unstakingRate) + " GRAM"}</span>
          </div>
          <div className={styles.check_token_more_info_row}>
            <span className={styles.check_token_more_info_label}>Estimatied Transaction Fee</span>
            <span className={styles.check_token_more_info_value}>
              <img className={styles.icon_base} src={require("../../../../assets/images/homepage/staking_base_eth_icon.png")} alt="klaytn" />
              <span className={styles.value}>{displayBalanceWei(isStaking ? stakingTxFee : unstakingTxFee, 8)} ETH</span>
            </span>
          </div>
        </div>
        <div className={styles.check_exchange_button_container}>
          <button
            className={cn("button", styles.check_exchange_button, { [styles.disabled]: isTxProcessing })}
            onClick={() => {
              if (isStaking) {
                runStakeTransaction();
              } else {
                runUnstakeTransaction();
              }
            }}
          >
            {isTxProcessing ? <Loading className={styles.loading} /> : <>{isStaking ? "Stake" : "Unstake"}</>}
          </button>
        </div>
      </Modal>
      <Modal outerClassName={styles.confirm_modal} visible={visibleModalConfirm} unquenchable={false} onClose={() => setVisibleModalConfirm(false)}>
        <div className={styles.modal_content}>
          <div className={styles.modal_title}>{modalTitle}</div>
          <div className={styles.modal_content_box}>
            <div className={styles.modal_contents}>{modalContents}</div>
          </div>
          <div className={styles.modal_btn_container}>
            <button className={cn(styles.modal_btn, { [styles.error]: modalTitle == "Error" })} onClick={() => setVisibleModalConfirm(false)}>
              OK
            </button>
          </div>
        </div>
      </Modal>
    </main>
  );
};
