import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./index.module.scss";
import { getShortenedBalance, displayBalanceFloat, converToWei, convertFromWei, sleep, displayBalanceWei, floorBalance } from "../../../../../utility/helpers";

import Modal from "../../../../elements/modal/Modal";
import { useIskraV2 } from "../../../../../module/hook/v2/iskra";
import normaServerClient from "../../../../../module/api/grampus/index";
import { Loading } from "../../../../elements/loading/Loading";

export const ExchangeCard = ({ selectedContractData, finishTx }) => {
  const MINIMUM_EXCHANGE_RATE = 0.9;
  const { walletAddress, exchangeAdapter, contractLoading, updateBalance, userData, login, changeNetwork } = useIskraV2();

  const [exchangeRateSourceToTarget, setExchangeRateSourceToTarget] = useState(1);
  const [exchangeRateTargetToSource, setExchangeRateTargetToSource] = useState(1);
  const [isSourceToTarget, setIsSourceToTarget] = useState(true);
  const [sourceAmount, setSourceAmount] = useState(0);
  const [targetAmount, setTargetAmount] = useState(0);

  const [visibleModalChecking, setVisibleModalChecking] = useState(false);
  const [isTxProcessing, setIsTxProcessing] = useState(false);
  const [exchangeTxFee, setExchangeTxFee] = useState(0);

  const [updateRemainCount, setUpdateRemainCount] = useState(0);

  useEffect(() => {
    updateExchangeRate();
  }, []);

  function switchSourceToTarget() {
    setIsSourceToTarget(!isSourceToTarget);
    setSourceAmount(0);
  }

  const updateExchangeRate = async () => {
    normaServerClient
      .getExchangeInfo()
      .then((res) => {
        const data = res.data;
        setExchangeRateSourceToTarget(data.iskTokenEstimatedChangePos);
        setExchangeRateTargetToSource(data.candyTokenEstimatedChangePos);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function onChangeSourceAmount(e) {
    setSourceAmount(Number(e.target.value));
  }

  useEffect(() => {
    setTargetAmount(sourceAmount * (isSourceToTarget ? exchangeRateTargetToSource : exchangeRateSourceToTarget));
  }, [sourceAmount, isSourceToTarget, exchangeRateSourceToTarget, exchangeRateTargetToSource]);

  useEffect(() => {
    if (contractLoading || !selectedContractData || !walletAddress || !walletAddress || !sourceAmount || !exchangeAdapter) {
      return;
    }

    const inputTokenAddress = isSourceToTarget ? selectedContractData.sourceTokenContractInfo?.Address : selectedContractData.targetTokenContractInfo?.Address;
    const inputAmount = converToWei(sourceAmount);
    const outputTokenAddress = isSourceToTarget ? selectedContractData.targetTokenContractInfo?.Address : selectedContractData.sourceTokenContractInfo?.Address;
    const outputAmount = converToWei(sourceAmount * (isSourceToTarget ? exchangeRateTargetToSource : exchangeRateSourceToTarget) * MINIMUM_EXCHANGE_RATE);
    changeNetwork(selectedContractData.exchangeContractInfo?.ChainId).then(() => {
      exchangeAdapter
        .getEstimatedExchangeGasFee(walletAddress, selectedContractData.exchangeContractInfo?.Address, inputTokenAddress, inputAmount, outputTokenAddress, outputAmount)
        .then((response) => {
          setExchangeTxFee(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, [sourceAmount, isSourceToTarget, selectedContractData, walletAddress, exchangeRateTargetToSource, exchangeRateSourceToTarget, contractLoading, exchangeAdapter]);

  function isInvalidSourceAmount() {
    if (sourceAmount <= 0) {
      return true;
    }

    if (isSourceToTarget) {
      return Number(sourceAmount) > Number(selectedContractData?.getSourceTokenBalance());
    } else {
      return Number(sourceAmount) > Number(selectedContractData?.getTargetTokenBalance());
    }
  }

  const runExchangeTx = async () => {
    setIsTxProcessing(true);

    const inputTokenAddress = isSourceToTarget ? selectedContractData.sourceTokenContractInfo.Address : selectedContractData.targetTokenContractInfo.Address;
    const inputAmount = converToWei(sourceAmount);
    const outputTokenAddress = isSourceToTarget ? selectedContractData.targetTokenContractInfo.Address : selectedContractData.sourceTokenContractInfo.Address;

    try {
      await login();
    } catch (e) {
      onFinishTx("Error", e.message);
      return;
    }

    try {
      await changeNetwork(selectedContractData.exchangeContractInfo.ChainId);
    } catch (e) {
      onFinishTx("Error", e.message);
      return;
    }

    let balanceWei = 0;
    try {
      balanceWei = await exchangeAdapter.balanceOf(walletAddress, inputTokenAddress);
    } catch (e) {
      onFinishTx("Error", e.message);
      return;
    }

    console.log("balanceWei");
    console.log(balanceWei);
    console.log("convertFromWei(balanceWei)");
    console.log(convertFromWei(balanceWei));
    console.log("sourceAmount");
    console.log(sourceAmount);

    if (Number(convertFromWei(balanceWei)) < Number(sourceAmount)) {
      onFinishTx("Error", "Insufficient balance.");
      return;
    }

    let allowanceEth = 0;
    try {
      allowanceEth = await exchangeAdapter.allowance(walletAddress, selectedContractData.exchangeContractInfo.Address, inputTokenAddress);
    } catch (e) {
      onFinishTx("Error", e.message);
      return;
    }

    if (Number(allowanceEth) < Number(sourceAmount) + 0.0001) {
      try {
        await exchangeAdapter.approve(walletAddress, selectedContractData.exchangeContractInfo.Address, inputTokenAddress, converToWei(Number(sourceAmount) - Number(allowanceEth) + 0.0001));
        await sleep(selectedContractData.chainDelay);
      } catch (e) {
        onFinishTx("Error", e.message);
        return;
      }
    }

    let estimatedOutputAmount;
    try {
      estimatedOutputAmount = await exchangeAdapter.getEstimatedChangePos(walletAddress, selectedContractData.exchangePoolContractInfo.Address, inputTokenAddress, inputAmount);
    } catch (e) {
      onFinishTx("Error", e.message);
      return;
    }

    if (!estimatedOutputAmount) {
      onFinishTx("Error", "Failed to get estimated output amount.");
      return;
    }

    const outputAmount = converToWei(Number(estimatedOutputAmount) * MINIMUM_EXCHANGE_RATE);

    let txHash;
    try {
      txHash = await exchangeAdapter.exchangePos(walletAddress, selectedContractData.exchangeContractInfo.Address, inputTokenAddress, inputAmount, outputTokenAddress, outputAmount);
    } catch (e) {
      onFinishTx("Error", e.message);
      return;
    }

    try {
      await normaServerClient.addExchangeItem(userData?.accessToken, isSourceToTarget ? 1 : 0, txHash, selectedContractData.exchangeContractInfo.Address, selectedContractData.exchangeContractInfo.ChainId);
    } catch (e) {
      console.log(e);
    }

    await sleep(selectedContractData.chainDelay);
    onFinishTx("Success", "The exchange was successful.");
    resetStatus();
  };

  function resetStatus() {
    setUpdateRemainCount(10);
    setSourceAmount(0);
    setTargetAmount(0);
  }

  function onFinishTx(modalTitle, modalContents) {
    setIsTxProcessing(false);
    setVisibleModalChecking(false);
    finishTx(modalTitle, modalContents);
  }

  useEffect(() => {
    if (updateRemainCount > 0) {
      setTimeout(() => {
        updateBalance();
        updateExchangeRate();

        setUpdateRemainCount(updateRemainCount - 1);
      }, 1000);
    }
  }, [updateRemainCount, walletAddress]);

  function closeCheckingModal() {
    setVisibleModalChecking(false);
  }

  return (
    <>
      {selectedContractData ? (
        <>
          <div className={styles.exchange_rate}>
            <div className={styles.exchange_rate_title}>Exchange Rate</div>
            <div className={styles.exchange_rate_content}>
              {isSourceToTarget ? (
                <>
                  <span>1</span> {selectedContractData?.sourceTokenSymbol} <span>: {getShortenedBalance(exchangeRateTargetToSource)}</span> {selectedContractData?.targetTokenSymbol}
                </>
              ) : (
                <>
                  <span>1</span> {selectedContractData?.targetTokenSymbol} <span>: {getShortenedBalance(exchangeRateSourceToTarget)}</span> {selectedContractData?.sourceTokenSymbol}
                </>
              )}
            </div>
          </div>
          <div className={styles.volume}>Balance : {isSourceToTarget ? displayBalanceFloat(selectedContractData?.getSourceTokenBalance()) : displayBalanceFloat(selectedContractData?.getTargetTokenBalance())}</div>
          <div className={styles.token_info_container}>
            <div className={cn(styles.row, styles.from)}>
              <div className={cn(styles.col, styles.from)}>From</div>

              <div className={cn(styles.col, styles.button, styles.mobile_button)}>
                <button className={cn("button", styles.max_button)} onClick={() => (isSourceToTarget ? setSourceAmount(floorBalance(selectedContractData?.getSourceTokenBalance(), 4)) : setSourceAmount(floorBalance(selectedContractData?.getTargetTokenBalance(), 4)))}>
                  MAX
                </button>
              </div>
            </div>
            <div className={styles.row}>
              <div className={cn(styles.col, styles.icon)}>{isSourceToTarget ? <img src={selectedContractData?.sourceTokenImg} alt={selectedContractData?.sourceTokenSymbol} /> : <img src={selectedContractData?.targetTokenImg} alt={selectedContractData?.targetTokenSymbol} />}</div>
              <div className={cn(styles.col, styles.symbol)}>{isSourceToTarget ? selectedContractData?.sourceTokenSymbol : selectedContractData?.targetTokenSymbol}</div>
              <div className={cn(styles.col, styles.amount)}>
                <input type="number" value={sourceAmount ? getShortenedBalance(sourceAmount) : ""} onChange={onChangeSourceAmount} placeholder="0.0000" />
              </div>
              <div className={cn(styles.col, styles.button)}>
                <button className={cn("button", styles.max_button)} onClick={() => (isSourceToTarget ? setSourceAmount(floorBalance(selectedContractData?.getSourceTokenBalance(), 4)) : setSourceAmount(floorBalance(selectedContractData?.getTargetTokenBalance(), 4)))}>
                  MAX
                </button>
              </div>
            </div>
          </div>
          <div className={styles.change_button_container}>
            <button className={cn(styles.change_button)} onClick={() => switchSourceToTarget()}>
              <img src={require("../../../../../assets/images/homepage/change.png")} alt="change" />
            </button>
          </div>
          <div className={styles.volume}>Balance : {isSourceToTarget ? displayBalanceFloat(selectedContractData?.getTargetTokenBalance()) : displayBalanceFloat(selectedContractData?.getSourceTokenBalance())}</div>
          <div className={styles.token_info_container}>
            <div className={cn(styles.row)}>
              <div className={cn(styles.col, styles.from)}>To</div>
            </div>
            <div className={styles.row}>
              <div className={cn(styles.col, styles.icon)}>{isSourceToTarget ? <img src={selectedContractData?.targetTokenImg} alt={selectedContractData?.targetTokenSymbol} /> : <img src={selectedContractData?.sourceTokenImg} alt={selectedContractData?.sourceTokenSymbol} />} </div>
              <div className={cn(styles.col, styles.symbol)}>{isSourceToTarget ? selectedContractData?.targetTokenSymbol : selectedContractData?.sourceTokenSymbol}</div>
              <div className={cn(styles.col, styles.amount)}>{getShortenedBalance(targetAmount)}</div>
              <div className={cn(styles.col, styles.button)}></div>
            </div>
          </div>
          <div className={styles.exchange_button_container}>
            <button className={cn("button", styles.exchange_button, { [styles.disabled]: isInvalidSourceAmount() })} onClick={() => setVisibleModalChecking(true)}>
              Exchange
            </button>
          </div>
        </>
      ) : (
        <div className={styles.loading_container}>
          <Loading className={styles.loading} />
        </div>
      )}

      <Modal outerClassName={styles.check_modal} visible={visibleModalChecking} onClose={closeCheckingModal} unquenchable={isTxProcessing}>
        <div className={styles.check_title}>Check</div>
        <div className={styles.check_token_info_container}>
          <div className={cn(styles.row, styles.from)}>From</div>
          <div className={styles.row}>
            <div className={cn(styles.col, styles.icon)}>{isSourceToTarget ? <img src={selectedContractData?.sourceTokenImg} alt={selectedContractData?.sourceTokenSymbol} /> : <img src={selectedContractData?.targetTokenImg} alt={selectedContractData?.targetTokenSymbol} />}</div>
            <div className={styles.mobile_row}>
              <div className={cn(styles.col, styles.symbol)}>{isSourceToTarget ? selectedContractData?.sourceTokenSymbol : selectedContractData?.targetTokenSymbol}</div>
              <div className={cn(styles.col, styles.amount)}>{displayBalanceFloat(sourceAmount)}</div>
            </div>
          </div>
        </div>
        <div className={styles.check_change_button_container}>
          <img src={require("../../../../../assets/images/homepage/check_change.png")} alt="change" />
        </div>
        <div className={styles.check_token_info_container}>
          <div className={cn(styles.row, styles.from)}>To</div>
          <div className={styles.row}>
            <div className={cn(styles.col, styles.icon)}>{isSourceToTarget ? <img src={selectedContractData?.targetTokenImg} alt={selectedContractData?.targetTokenSymbol} /> : <img src={selectedContractData?.sourceTokenImg} alt={selectedContractData?.sourceTokenSymbol} />}</div>
            <div className={styles.mobile_row}>
              <div className={cn(styles.col, styles.symbol)}>{isSourceToTarget ? selectedContractData?.targetTokenSymbol : selectedContractData?.sourceTokenSymbol}</div>
              <div className={cn(styles.col, styles.amount)}>{displayBalanceFloat(targetAmount)}</div>
            </div>
          </div>
        </div>
        <div className={styles.check_token_more_info_container}>
          <div className={styles.check_token_more_info_row}>
            <span className={styles.check_token_more_info_label}>Exchange Rate</span>
            <span className={styles.check_token_more_info_value}>{isSourceToTarget ? "1 " + selectedContractData?.sourceTokenSymbol + " = " + displayBalanceFloat(exchangeRateSourceToTarget) + " " + selectedContractData?.targetTokenSymbol : "1 " + selectedContractData?.targetTokenSymbol + " = " + displayBalanceFloat(exchangeRateTargetToSource) + " " + selectedContractData?.sourceTokenSymbol}</span>
          </div>
          <div className={styles.check_token_more_info_row}>
            <span className={styles.check_token_more_info_label}>Estimatied Transaction Fee</span>
            <span className={styles.check_token_more_info_value}>
              <img className={styles.icon} src={selectedContractData?.chainIcon} alt={selectedContractData?.chainSymbol} />
              <span className={styles.value}>
                {displayBalanceWei(exchangeTxFee)} {selectedContractData?.chainSymbol}
              </span>
            </span>
          </div>
        </div>
        <div className={styles.check_exchange_button_container}>
          <button
            className={cn("button", styles.check_exchange_button, { [styles.disabled]: isTxProcessing })}
            onClick={() => {
              runExchangeTx();
            }}
          >
            {isTxProcessing ? <Loading className={styles.loading} /> : "Exchange"}
          </button>
        </div>
      </Modal>
    </>
  );
};
