import React, { useEffect } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from "./components/header/v2";
import { Footer } from "./components/footer/v2";
import { Providers } from "./providers";
import { Main as LandingMain } from "./components/pages/v2/landing";
import { Main as NormalandMain } from "./components/pages/v2/normaland";
import { Main as OcsMain } from "./components/pages/v2/ocs";
import { WaterMark } from "./components/elements/watermark/WaterMark";
import { Exchange } from "./components/pages/v2/exchange";
import { Staking } from "./components/pages/v2/staking";
import { UpArrow } from "./components/elements/up_arrow/UpArrow";
import { Dashboard } from "./components/pages/v2/dashboard";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Providers>
          <Header />
          <Routes>
            <Route path="/" element={<LandingMain />}></Route>
            {/* TODO: 대시보드 페이지 추가 시 주석 해제 */}
            {/* <Route path="/dashboard" element={<Dashboard />}></Route> */}
            <Route path="/staking" element={<Staking />}></Route>
            <Route path="/exchange" element={<Exchange />}></Route>
            <Route path="/game/normaland" element={<NormalandMain />}></Route>
            <Route path="/game/juicyadventure" element={<OcsMain />}></Route>
          </Routes>
          <Footer />
          <WaterMark />
          <UpArrow className="mobile" />
        </Providers>
      </BrowserRouter>
    </div>
  );
}

export default App;
