import ERC20Adapter from "../erc20";
import ExchangeAbi from "../../../../../../assets/contract/Exchange.abi";
import ExchangePoolAbi from "../../../../../../assets/contract/ExchangePool.abi";
import { call, send, getGas } from "../../module";
import { convertFromWei } from "../../../../../../utility/helpers";
import Web3 from "web3";

class ExchangeAdapter extends ERC20Adapter {
  async loadAbis() {
    super.loadAbis();

    const exchangeRes = await fetch(ExchangeAbi);
    const exchangeData = await exchangeRes.text();

    this.exchangeContract = new this.web3.eth.Contract(JSON.parse(exchangeData));

    const exchangePoolRes = await fetch(ExchangePoolAbi);
    const exchangePoolData = await exchangePoolRes.text();

    this.exchangePoolContract = new this.web3.eth.Contract(JSON.parse(exchangePoolData));
  }

  async getEstimatedChangePos(walletAddress, contractAddress, inputTokenAddress, amount) {
    if (!walletAddress || !contractAddress || !inputTokenAddress || !amount || !this.exchangePoolContract) {
      return 0;
    }

    const params = [
      {
        from: walletAddress,
        to: contractAddress,
        data: this.exchangePoolContract.methods.estimatePos(inputTokenAddress, amount).encodeABI(),
      },
    ];

    return convertFromWei(await call(this.provider, params));
  }

  async exchangePos(walletAddress, contractAddress, inputTokenAddress, inputAmount, outputTokenAddress, minOutputAmount) {
    if (!this.exchangeContract) {
      console.error("exchangeContract is null in exchangePos()");
      console.trace();
      throw new Error("Contract initialization failed.\nPlease refresh the page and try again.\ncode : 8102");
    }

    const web3 = new Web3(this.provider);
    const gasPrice = await web3.eth.getGasPrice();

    const params = [
      {
        from: walletAddress,
        to: contractAddress,
        gas: "0x55730", // 350000
        gasPrice: web3.utils.toHex(gasPrice),
        data: this.exchangeContract.methods.exchangeKctPos(inputTokenAddress, inputAmount, outputTokenAddress, minOutputAmount, []).encodeABI(),
      },
    ];

    return send(this.provider, params);
  }

  async getEstimatedExchangeGasFee(walletAddress, contractAddress, inputTokenAddress, inputAmount, outputTokenAddress, minOutputAmount) {
    if (!walletAddress || !contractAddress || !inputTokenAddress || !inputAmount || !outputTokenAddress || !minOutputAmount || !this.exchangeContract || !this.erc20Contract) {
      return 0;
    }

    let params = [
      {
        from: walletAddress,
        to: contractAddress,
        data: this.exchangeContract.methods.exchangeKctPos(inputTokenAddress, inputAmount, outputTokenAddress, minOutputAmount, []).encodeABI(),
      },
    ];

    const web3 = new Web3(this.provider);
    const gasPrice = await web3.eth.getGasPrice();

    try {
      return this.web3.utils.toNumber(await getGas(this.provider, params)) * gasPrice;
    } catch (error) {
      params = [
        {
          from: walletAddress,
          to: inputTokenAddress,
          data: this.erc20Contract.methods.approve(contractAddress, inputAmount).encodeABI(),
        },
      ];

      return this.web3.utils.toNumber(await getGas(this.provider, params)) * gasPrice;
    }
  }
}

export default ExchangeAdapter;
