import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import cn from "classnames";

const data = {
  chain: {
    1: {
      native: require("../../../assets/images/homepage/symbol/eth_native.png"),
      small: require("../../../assets/images/homepage/symbol/eth_small.png"),
    },
    11155111: {
      // TESTNET
      native: require("../../../assets/images/homepage/symbol/eth_native.png"),
      small: require("../../../assets/images/homepage/symbol/eth_small.png"),
    },
    8453: {
      native: require("../../../assets/images/homepage/symbol/base_native.png"),
      small: require("../../../assets/images/homepage/symbol/base_small.png"),
    },
    84532: {
      // TESTNET
      native: require("../../../assets/images/homepage/symbol/base_native.png"),
      small: require("../../../assets/images/homepage/symbol/base_small.png"),
    },
    8217: {
      native: require("../../../assets/images/homepage/symbol/kaia_native.png"),
      small: require("../../../assets/images/homepage/symbol/kaia_small.png"),
    },
    1001: {
      // TESTNET
      native: require("../../../assets/images/homepage/symbol/kaia_native.png"),
      small: require("../../../assets/images/homepage/symbol/kaia_small.png"),
    },
  },
  token: {
    gram: require("../../../assets/images/homepage/symbol/gram_big.png"),
    candy: require("../../../assets/images/homepage/symbol/candy_big.png"),
    staking: require("../../../assets/images/homepage/symbol/sgram_big.png"),
    usdc: require("../../../assets/images/homepage/symbol/usdc_big.png"),
    jelly: require("../../../assets/images/homepage/symbol/jelly_big.png"),
  },
};

export const TokenImage = ({ symbol, chainId, className }) => {
  return (
    <div className={cn(styles.symbol_container, className)}>
      {!symbol ? (
        <img src={data.chain[chainId].native} className={styles.symbol} alt="chain" />
      ) : (
        <>
          <img src={data.token[symbol.toLowerCase()]} className={styles.symbol} alt="token" />
          <img src={data.chain[chainId].small} className={styles.chain} alt="chain" />
        </>
      )}
    </div>
  );
};
