import ERC20Adapter from "../erc20";
import StakingAbi from "../../../../../../assets/contract/Staking.abi";
import { call, send, getGas } from "../../module";
import { convertFromWei } from "../../../../../../utility/helpers";
import Web3 from "web3";

class StakingAdapter extends ERC20Adapter {
  async loadAbis() {
    super.loadAbis();
    const res = await fetch(StakingAbi);
    const data = await res.text();

    this.stakingContract = new this.web3.eth.Contract(JSON.parse(data));
  }

  async stake(walletAddress, contractAddress, amount) {
    if (!this.stakingContract) {
      console.error("stakingContract is null in stake()");
      console.trace();
      throw new Error("Contract initialization failed.\nPlease refresh the page and try again.\ncode : 8001");
    }

    const web3 = new Web3(this.provider);
    const gasPrice = await web3.eth.getGasPrice();

    const params = [
      {
        from: walletAddress,
        to: contractAddress,
        gas: web3.utils.toHex(3000000),
        gasPrice: web3.utils.toHex(gasPrice),
        data: this.stakingContract.methods.stake(amount).encodeABI(),
      },
    ];

    return send(this.provider, params);
  }

  async getEstimatedStakingGasFee(walletAddress, contractAddress, tokenAddress, amount) {
    if (!walletAddress || !contractAddress || !tokenAddress || !amount || !this.stakingContract || !this.erc20Contract) {
      return 0;
    }

    let params = [
      {
        from: walletAddress,
        to: contractAddress,
        data: this.stakingContract.methods.stake(amount).encodeABI(),
      },
    ];

    const web3 = new Web3(this.provider);
    const gasPrice = await web3.eth.getGasPrice();

    try {
      return this.web3.utils.toNumber(await getGas(this.provider, params)) * gasPrice;
    } catch (error) {
      params = [
        {
          from: walletAddress,
          to: tokenAddress,
          data: this.erc20Contract.methods.increaseAllowance(contractAddress, amount).encodeABI(),
        },
      ];

      try {
        return this.web3.utils.toNumber(await getGas(this.provider, params)) * gasPrice;
      } catch (error) {
        params = [
          {
            from: walletAddress,
            to: tokenAddress,
            data: this.erc20Contract.methods.approve(contractAddress, amount).encodeABI(),
          },
        ];

        try {
          return this.web3.utils.toNumber(await getGas(this.provider, params)) * gasPrice;
        } catch (error) {
          console.error("Failed to get gas fee in getEstimatedStakingGasFee()");
          console.trace();
        }
      }
    }
  }

  async getEstimatedUnstakingGasFee(walletAddress, contractAddress, tokenAddress, amount) {
    if (!walletAddress || !contractAddress || !tokenAddress || !amount || !this.stakingContract || !this.erc20Contract) {
      return 0;
    }

    let params = [
      {
        from: walletAddress,
        to: contractAddress,
        data: this.stakingContract.methods.withdraw(amount).encodeABI(),
      },
    ];

    const web3 = new Web3(this.provider);
    const gasPrice = await web3.eth.getGasPrice();

    try {
      return this.web3.utils.toNumber(await getGas(this.provider, params)) * gasPrice;
    } catch (error) {
      params = [
        {
          from: walletAddress,
          to: tokenAddress,
          data: this.erc20Contract.methods.increaseAllowance(contractAddress, amount).encodeABI(),
        },
      ];

      try {
        return this.web3.utils.toNumber(await getGas(this.provider, params)) * gasPrice;
      } catch (error) {
        params = [
          {
            from: walletAddress,
            to: tokenAddress,
            data: this.erc20Contract.methods.approve(contractAddress, amount).encodeABI(),
          },
        ];

        try {
          return this.web3.utils.toNumber(await getGas(this.provider, params)) * gasPrice;
        } catch (error) {
          console.error("Failed to get gas fee in getEstimatedUnstakingGasFee()");
          console.trace();
        }
      }
    }
  }

  async withdraw(walletAddress, contractAddress, amount) {
    if (!this.stakingContract) {
      console.error("stakingContract is null in withdraw()");
      console.trace();
      throw new Error("Contract initialization failed.\nPlease refresh the page and try again.\ncode : 8004");
    }

    const web3 = new Web3(this.provider);
    const gasPrice = await web3.eth.getGasPrice();

    const params = [
      {
        from: walletAddress,
        to: contractAddress,
        gas: web3.utils.toHex(3000000),
        gasPrice: web3.utils.toHex(gasPrice),
        data: this.stakingContract.methods.withdraw(amount).encodeABI(),
      },
    ];

    return send(this.provider, params);
  }

  async convertOriginTokenToStaking(contractAddress, amount) {
    if (!this.stakingContract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: this.iskraInterface.getWalletAddress(),
        data: this.stakingContract.methods.convertOriginTokenToStaking(amount).encodeABI(),
      },
    ];

    return convertFromWei(await call(this.provider, params));
  }

  async convertStakingTokenToOrigin(contractAddress, amount) {
    if (!this.stakingContract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: this.iskraInterface.getWalletAddress(),
        data: this.stakingContract.methods.convertStakingTokenToOrigin(amount).encodeABI(),
      },
    ];

    return convertFromWei(await call(this.provider, params));
  }

  async getStartInflationFromTimestamp(contractAddress) {
    if (!this.stakingContract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: this.iskraInterface.getWalletAddress(),
        data: this.stakingContract.methods.startInflationFromTimestamp().encodeABI(),
      },
    ];

    return Web3.utils.toNumber(await call(this.provider, params));
  }

  async getInflationPerSec(contractAddress) {
    if (!this.stakingContract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: this.iskraInterface.getWalletAddress(),
        data: this.stakingContract.methods.inflationPerSec().encodeABI(),
      },
    ];

    return convertFromWei(await call(this.provider, params));
  }

  async getTotalPooledOrigin(contractAddress) {
    if (!this.stakingContract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: this.iskraInterface.getWalletAddress(),
        data: this.stakingContract.methods.getTotalPooledOrigin().encodeABI(),
      },
    ];

    return convertFromWei(await call(this.provider, params));
  }

  async expectedEarning(contractAddress, amount) {
    if (!this.stakingContract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: this.iskraInterface.getWalletAddress(),
        data: this.stakingContract.methods.expectedEarning(amount).encodeABI(),
      },
    ];

    return convertFromWei(await call(this.provider, params));
  }
}

export default StakingAdapter;
