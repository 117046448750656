import React, { useState, useRef } from "react";

import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import cn from "classnames";
import { ReactComponent as MenuIcon } from "../../../assets/images/homepage/menu.svg";

import { useEffect } from "react";
import { useLang } from "../../../module/hook/v1/hooks/useLang";
import { User } from "../../elements/user/User";
import { sleep } from "../../../utility/helpers";
import { isMobile, isMobileWidth } from "../../../utility/helpers";
import { LinkButton } from "../../elements/linkbutton";

export const Header = () => {
  const expandContainerRef = useRef(null);
  const navRef = useRef(null);
  const [mouseEventSeq, setMouseEventSeq] = useState(0);

  const menuItems = [
    {
      text: "Games",
      subMenu: [
        {
          text: "Norma in Metaland",
          link: "/game/normaland",
        },
        {
          text: "Juicy Adventure",
          link: "/game/juicyadventure",
        },
      ],
      ref: useRef(null),
    },
    {
      text: "Exchange",
      subMenu: [
        {
          text: "$ISK-$CANDY",
          link: "/exchange?ex=candy",
        },
        // {
        //   text: "$GRAM-$JELLY",
        //   link: "/exchange?ex=jelly",
        // },
      ],
      ref: useRef(null),
    },
    {
      link: "/staking",
      text: "Staking",
    },
    {
      link: "https://snapshot.org/#/gramdao.eth",
      text: "Governance",
    },
    {
      link: "https://grampus-cwc.gitbook.io/gram_voyage/",
      text: "Whitepaper",
    },
  ];

  const [expandedKey, setExpandedKey] = useState(null);

  const [mobileMenu, setMobileMenu] = useState(false);
  const { language, setLanguage } = useLang();

  useEffect(() => {
    if (mobileMenu) {
      disableBodyScroll(document.querySelector(".container"));
    } else {
      clearAllBodyScrollLocks();
    }
  }, [mobileMenu]);

  useEffect(() => {
    if (isMobile || isMobileWidth()) {
      return;
    }

    for (let item of menuItems) {
      if (item.subMenu) {
        item.ref.current.addEventListener("mouseenter", async () => {
          setMouseEventSeq((s) => s + 1);

          setExpandedKey(item.text);
          // set expandContainer's y position to navRef's bottom - 10px
          expandContainerRef.current.style.top = navRef.current.getBoundingClientRect().bottom - 10 + "px";
          // alpha: 0
          expandContainerRef.current.style.opacity = 0;
          // set padding.left to item.ref's left
          expandContainerRef.current.style.paddingLeft = item.ref.current.getBoundingClientRect().left + "px";

          expandContainerRef.current.style.display = "flex";

          await sleep(100);
          expandContainerRef.current.style.top = navRef.current.getBoundingClientRect().bottom + "px";
          expandContainerRef.current.style.opacity = 1;
        });
      }
    }

    navRef.current.addEventListener("mouseleave", async () => {
      const seq = mouseEventSeq;
      expandContainerRef.current.style.top = expandContainerRef.current.getBoundingClientRect().top - 10 + "px";
      expandContainerRef.current.style.opacity = 0;

      await sleep(200);

      if (seq !== mouseEventSeq) return;

      expandContainerRef.current.style.display = "none";
      setExpandedKey(null);
    });
  }, [menuItems, expandContainerRef, navRef]);

  const onExpandedClicked = (item) => {
    if (!isMobile && !isMobileWidth()) {
      return;
    }

    if (expandedKey === item.text) {
      setExpandedKey(null);
    } else {
      setExpandedKey(item.text);
    }
  };

  return (
    <>
      <nav ref={navRef}>
        <div className={cn("container", styles.container)}>
          <div className={styles.box_container}>
            <LinkButton className={styles.logo_container} link="/">
              <img className={styles.logo} src={require("../../../assets/images/homepage/gram_logo.png")} />
            </LinkButton>
            <button className={styles.mobile_menu} onClick={() => setMobileMenu((s) => !s)}>
              <img src={require("../../../assets/images/homepage/mobile_menu.png")} />
            </button>
            <div className={cn(styles.menu_bg, { [styles.active]: mobileMenu })} />
            <div id="menu_container" className={cn(styles.menu_container, { [styles.active]: mobileMenu })}>
              <LinkButton className={cn("mobile", styles.logo_container)} link="/">
                <img className={styles.logo} src={require("../../../assets/images/homepage/gram_logo.png")} />
              </LinkButton>
              {menuItems.map((item, index) => (
                <span className={styles.menu} key={index}>
                  {item.subMenu ? (
                    <>
                      <span ref={item.ref} className={cn(styles.expand_span, { [styles.expanded]: expandedKey === item.text })} onClick={() => onExpandedClicked(item)}>
                        {item.text}
                      </span>
                      {expandedKey === item.text && (
                        <div className={styles.expand_container}>
                          {item.subMenu.map((subItem, index) => (
                            <LinkButton className={styles.sub_menu} key={index} link={subItem.link} onClick={() => setMobileMenu(false) || setExpandedKey(null)}>
                              <span className={styles.span}>{subItem.text}</span>
                            </LinkButton>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <LinkButton className={cn(styles.span)} link={item.link} onClick={() => setMobileMenu(false)}>
                      {item.text}
                    </LinkButton>
                  )}
                </span>
              ))}

              <div className={styles.connect}>
                <User collapsePanel={() => setMobileMenu(false)} isMobileLayout={isMobile || isMobileWidth()} />
              </div>

              <div className={cn("mobile", styles.copy_right_box)}>
                COPYRIGHT (c) GRAMPUS CWC PTE. LTD.
                <br />
                ALL RIGHTS RESERVED.
              </div>
            </div>
          </div>
          <div ref={expandContainerRef} className={cn(styles.fixed_box_container)}>
            {menuItems
              .find((item) => expandedKey === item.text)
              ?.subMenu.map((item, index) => (
                <LinkButton className={styles.menu} key={index} link={item.link} onClick={() => setMobileMenu(false)}>
                  <span className={styles.span}>{item.text}</span>
                </LinkButton>
              ))}
          </div>
        </div>
      </nav>
    </>
  );
};
