export async function call(provider, params) {
  return new Promise((resolve, reject) => {
    provider
      .request({
        method: "eth_call",
        params,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function send(provider, params) {
  return new Promise((resolve, reject) => {
    provider
      .request({
        method: "eth_sendTransaction",
        params,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getGas(provider, params) {
  return new Promise((resolve, reject) => {
    provider
      .request({
        method: "eth_estimateGas",
        params,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
