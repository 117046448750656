import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { FavorletProvider } from "./module/hook/v1/providers/FavorletProvider";
import { KaikasProvider } from "./module/hook/v1/providers/KaikasProvider";
import { LangProvider } from "./module/hook/v1/providers/LangProvider";
import { MetamaskProvider } from "./module/hook/v1/providers/MetamaskProvider";
import { MyInfoProvider } from "./module/hook/v1/providers/MyInfoProvider";
import { WalletProvider } from "./module/hook/v1/providers/WalletProvider";
import "@rainbow-me/rainbowkit/styles.css";
import { RainbowKitAuthenticationProvider, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { STATE, wagmiConfig, authenticationAdapter, setCallback } from "./module/logic/authenticator";
import { ErrorToastProvider } from "./module/hook/v2/error";
import { IskraProviderV2 } from "./module/hook/v2/iskra";

export const Providers = ({ children }) => {
  const [state, setState] = React.useState(STATE.LOADING);

  useEffect(() => {
    setCallback(setState);
    setState(STATE.UNAUTHENTICATED);
  }, []);

  return (
    <>
      <ToastContainer />
      <ErrorToastProvider>
        <WagmiProvider config={wagmiConfig}>
          <QueryClientProvider client={new QueryClient()}>
            <RainbowKitAuthenticationProvider adapter={authenticationAdapter} status={state}>
              <RainbowKitProvider>
                <IskraProviderV2>
                  <LangProvider>
                    <MetamaskProvider>
                      <KaikasProvider>
                        <FavorletProvider>
                          <WalletProvider>
                            <MyInfoProvider>{children}</MyInfoProvider>
                          </WalletProvider>
                        </FavorletProvider>
                      </KaikasProvider>
                    </MetamaskProvider>
                  </LangProvider>
                </IskraProviderV2>
              </RainbowKitProvider>
            </RainbowKitAuthenticationProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </ErrorToastProvider>
    </>
  );
};
