import Web3 from "web3";

class Web3Adapter {
  constructor(iskraInterface) {
    this.iskraInterface = iskraInterface;
    this.provider = null;
    this.web3 = new Web3();
    this.loadAbis();
  }

  iniProvider(p) {
    this.provider = p;
  }

  async loadAbis() {}
}

export default Web3Adapter;
